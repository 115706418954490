<template>
  <!-- 解决方案 -->
  <div class="website-css mobile-css">
    <MenuHead @func="getMsgFormSon"></MenuHead>
    <!-- banner beg -->
    <div class="Solution-banner AboutUs-banner">
      <div class="w1200">
        <img
          src="../assets/image/Website-img/bannericon1.png"
          class="banner-icon banner-i con1 animated pulse"
        />
        <img
          src="../assets/image/Website-img/aboutbanner1.png"
          class="banner-icon banner-icon3 animated flipIn X"
        />
        <div class="banner-title solution-title Mobile-pad25">
          <p class="animated flipInX">{{ this.languageList.bannertitle }}</p>
          <p>{{ this.languageList.bannerContent }}</p>
        </div>
        <!-- <img src="../assets/image/Website-img/bannericon1.png" class="banner-icon3" /> -->
      </div>
    </div>
    <!-- banner end -->

    <div class="w1200 Mobile-pad25">
      <!--  关于大象智酷 beg -->
      <div class="brain">
        <div class="title-font36">
          {{ this.languageList.titleText }}
          <span></span>
        </div>
      </div>
      <div class="About-div">
        <div class="About-img">
          <img src="../assets/image/Website-img/gongsi.png" />
        </div>
        <div class="About-text">
          <p>{{ this.languageList.textContent1 }}</p>
          <p>{{ this.languageList.textContent2 }}</p>
          <p>{{ this.languageList.textContent3 }}</p>
        </div>
      </div>
      <!--  关于大象智酷 end -->
    </div>
    <!-- 加入我们 beg -->

    <div class="Enterprise-bg-f8 over">
      <div class="w1200"></div>
      <div class="title-font36">
        {{ this.languageList.JoinUsTitle }}
        <span></span>
      </div>
      <div class="w1200 Mobile-pad25">
        <ul class="JoinUs">
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
          <swiper
            :options="swiperOption"
            class="swiper-div"
            ref="mySwiper"
            v-if="bannerList.length != 0" 
          >
            <swiper-slide
              class="swiper-slide-div"
              v-for="(item, index) in this.PositionList"
              :key="index"
            >
              <div class="About-card">
                <p>{{ item.title }}</p>
                <a @click="Card(index)">{{ item.number }}</a>
              </div>
            </swiper-slide>
          </swiper>
        </ul>
      </div>
    </div>
    <!-- 加入我们 end -->

    <!-- 企业文化 beg -->
    <div class="CorporateCulture">
      <p class="font48">{{ this.languageList.banner2text }}</p>
      <img src="../assets/image/Website-img/qiyewenhua.png" class="" />
    </div>
    <!-- 企业文化 end -->

    <!-- 联系我们beg -->
    <div class="w1200">
      <div class="title-font36 pad-bott35">
        {{ this.languageList.contactusTitle }}
        <span></span>
      </div>
      <div class="Aboutus">
        <div
          class="dashed-div"
          v-for="(item, index) in this.languageList.list"
          :key="index"
        >
          <img :src="item.img" />
          <div class="Aboutus-text">
            <p v-for="(items, indexs) in item.content" :key="indexs">
              {{ items.text }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 联系我们end -->
    <web-bottom ref="child"></web-bottom>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import ChinesePosition from '../assets/json/ChinesePosition-Json.json'
import EnglishPosition from '../assets/json/EnglishPosition-Json.json'
import ChineseJson from '../assets/json/Chinese-Json.json'
import EnglishJson from '../assets/json/English-Json.json'
import MenuHead from '../components/MenuHead'
import WebBottom from '../components/WebBottom'
import 'swiper/css/swiper.css'
export default {
  name: 'AboutUs',
  components: {
    Swiper,
    SwiperSlide,
    MenuHead,
    WebBottom,
  },
  metaInfo: {
    title: '关于我们-北京大象智酷科技有限公司', // set a title
    meta: [
      // set meta
      {
        name: 'keyWords',
        content: '教育,阅读,脑科学,未来之光,大象智酷',
      },
      {
        name: 'description',
        content:
          '北京大象智酷科技有限公司拥有多项软件著作权及专利保护,具备业内高水平的认知能力训练及测评的技术基础.目前,未来之光、倍速阅读、小象脑力,教育阅读,脑科学锻炼等创意脑应用产品已经面世,同时在相关领域的科学研究也在不断探.',
      },
    ],
    // link: [{                 // set link
    //  		rel: 'asstes',
    //   	href: 'https://assets-cdn.github.com/'
    // }]
  },
  data() {
    return {
      ChineseJson: JSON.parse(JSON.stringify(ChineseJson)), //中文配表
      EnglishJson: JSON.parse(JSON.stringify(EnglishJson)), //英文配表
      languageJson: [],
      languageList: [],
      PositionList: [],
      langtype: null,
      ChinesePosition: JSON.parse(JSON.stringify(ChinesePosition)),
      EnglishPosition: JSON.parse(JSON.stringify(EnglishPosition)),
      bannerList: [
        { img: require('../assets/image/Website-img/banner1.jpg') },
        { img: require('../assets/image/Website-img/banner1.jpg') },
        { img: require('../assets/image/Website-img/banner1.jpg') },
      ],
      swiperOption: {
        slidesPerView: 4,
        paginationClickable: true,
        spaceBetween: 30,
        loop: true,
        // autoplay: {
        //   delay: 2000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false,
        // },
        // 显示分页
        pagination: {
          el: '.swiper-pagination',
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  props: {
    msg: String,
  },
  created() {
    // this.langtype = localStorage.getItem("LanguageTypes");
    // this.IFType();
    if (localStorage.getItem('LanguageTypes') == undefined) {
      this.langtype = 1
      this.PositionList = this.ChinesePosition
      this.IFType()
    } else {
      this.langtype = localStorage.getItem('LanguageTypes')
      this.IFType()
    }
  },
  mounted() {},
  methods: {
    IFType() {
      if (this.langtype == 1) {
        this.languageJson = this.ChineseJson
        this.PositionList = this.ChinesePosition
        this.getList()
      } else {
        this.languageJson = this.EnglishJson
        this.PositionList = this.EnglishPosition
        this.getList()
      }
    },
    getMsgFormSon(data) {
      // this.msgFormSon = data;
      this.langtype = data
      this.IFType()
      this.$refs.child.sing(this.langtype)
    },
    getList() {
      // this.ChineseJson.fo
      this.languageJson.forEach((item) => {
        if (item.title == 'AboutUs') {
          this.languageList = item.List[0]
        }
      })
    },
    // 点击职位
    Card(index) {
      this.$router.push({ name: 'Join-us', query: { index: index } })
    },
  },
}
</script>

<style scoped>
.AboutUs-banner {
  width: 100%;
  height: 535px;
  background: linear-gradient(to right, #27ccfe, #0d66fa);
}
.Solution-banner {
}
.brain-ul li {
  float: left;
  width: 400px;
  height: 300px;
}
.swiper-slide {
  width: 248px !important;
  margin-right: 0px !important;
  height: 220px;
  margin-left: 30px;
  box-shadow: 0px 0px 10px #e5e5e5;
  font-size: 18px;
  color: #000000;
  float: left;
  text-align: center;
  background: #ffffff;
}
.swiper-slide:nth-of-type(1) {
  margin-left: 0px;
}
.swiper-div {
  width: 1080px;
  /* box-sizing: border-box; */
  padding: 10px;
}
/* .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l4.2%2C4.2L8.4%2C22l17.8%2C17.8L22%2C44L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  width: 12%;
  height: 6%;
}
.swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L5%2C44l-4.2-4.2L18.6%2C22L0.8%2C4.2L5%2C0z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
  width: 9%;
  height: 6%;
} */
.swiper-slide p {
  padding: 78px 0px 35px 0px;
}
.swiper-slide a {
  width: 100px;
  margin: auto;
  height: 30px;
  display: block;
  font-size: 14px;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  bottom: 50px;
  left: 50%;
  margin-left: -50px;
  background: #1a99fc;
}
.swiper-button-next,
.swiper-button-prev {
  color: #000000 !important;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 25px !important;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 0;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 0;
}
</style>